<template>
    <div>
        <a-row :gutter="24" type="flex">

            <!-- Projects Table Column -->
            <a-col :span="24" class="mb-24">

                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12" class="top-left-header">
                                <h5 class="font-semibold m-0">User History
                                    <a-spin v-if="confirmLoading"/>
                                </h5>
                            </a-col>
                            <a-col :span="24" :md="12" class="">
                                <a-input
                                        placeholder="Phone"
                                        v-model="phone"
                                        :disabled="confirmLoading"
                                        :allow-clear="true"
                                        :size="'default'"
                                        :style="{width: '200px'}"/>
                                <a-button type="primary" @click="getUserHistoryByPhone" class="show-btn">
                                    Show
                                </a-button>
                            </a-col>
                        </a-row>
                    </template>
                    <a-table :columns="columnData"
                             :data-source="userHistoryList"
                             :pagination="false"
                             :scroll="{x: true}">

                        <template slot="name" slot-scope="text">
                            <a>{{ text }}</a>
                        </template>
                    </a-table>
                </a-card>

            </a-col>
            <!-- / Projects Table Column -->

        </a-row>
    </div>
</template>

<script>
import moment from "moment/moment"
import {sendRequest} from '@/http/axios.method';

const COLUMN_DATA = [
    {
        title: 'Status',
        dataIndex: 'status',
        class: 'font-semibold text-muted',
        width: 130,
    },
    {
        title: 'MSISDN',
        dataIndex: 'MSISDN',
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        class: 'font-semibold text-muted',
    },
    {
        title: 'Flag',
        dataIndex: 'flag',
        class: 'font-semibold text-muted',
    },
    {
        title: 'Product ID',
        dataIndex: 'product_id',
        class: 'font-semibold text-muted',
    },

];

export default {
    name: "UserHistory",
    data() {
        return {
            config: {
                'Authorization': 'Bearer ' + this.$store.state.authUser.token
            },
            columnData: COLUMN_DATA,
            userHistoryData: [],
            userHistory: [],

            phone: '',
            confirmLoading: false,
        }
    },
    methods: {
        getUserHistoryByPhone() {
            if (this.phone.length === 0) {
                this.confirmLoading = false;
                return;
            }
            this.confirmLoading = true;
            sendRequest('post', 'admin/user-history-by-phone', {phone: this.phone}, this.config)
                .then(response => {
                    this.confirmLoading = false
                    if (response.success) {
                        this.phone = '';
                        this.userHistory = response.data
                        this.userHistoryData = this.userHistory.map(function (history, label) {
                            return {
                                key: label + 1,
                                status: history.status,
                                MSISDN: history.MSISDN,
                                created_at: moment(history.created_at).format('YYYY-MM-DD HH:mm:ss'),
                                flag: history.flag,
                                product_id: history.product_id,

                            };
                        });
                    } else {
                        if (response.status_code === 401) {
                            this.$store.dispatch('logout');
                            this.$router.push('/login');
                        }
                        if (response.status_code === 500) {
                            this.userHistoryData = [];
                        }
                    }
                })
                .catch(() => {
                    this.confirmLoading = false
                })
                .finally(() => {
                    this.confirmLoading = false
                })
        },
    },

    computed: {
        userHistoryList: function () {
            return this.userHistoryData;
        }
    }
}
</script>

<style scoped>
.show-btn {
    margin-left: 20px;
    width: 100px;
}

</style>
